<template>
  <div class="main">
    <a-form-model id="formLogin" ref="form" class="user-layout-login" :model="form" :rules="rules">

      <div class="code-box">
        <img style="margin: 0 auto;" :src="codeImgUrl">
      </div>
      <div class="code-msg">
        <img class="code-msg-icon" src="~@/assets/login/5.png">
        <span class="font14R">微信扫码即可完成登录</span>

      </div>

      <div class="user-login-other" :style="{opacity: defaultSettings.isQQLogin||defaultSettings.isWxLogin||defaultSettings.isAliLogin||defaultSettings.isIphoneLogin?1:0}">
        <div class="user-login-other-title">
          <div class="b"></div>
          <span class="font16R">或</span>
          <div class="b"></div>
        </div>
        <div class="other-type">
          <!-- <img v-if="defaultSettings.isQQLogin" class="other-type-icon" src="~@/assets/homeImg/qq.png" alt="" srcset=""> -->
          <!-- <img v-if="defaultSettings.isWxLogin" class="other-type-icon" src="~@/assets/login/5.png" @click="openOther('wxLogin')"> -->
          <img v-if="defaultSettings.isAliLogin" class="other-type-icon" src="~@/assets/login/4.png" @click="openOther('aliLogin')">
          <!-- <img v-if="defaultSettings.isIphoneLogin" class="other-type-icon" src="~@/assets/homeImg/iphone.png" alt="" srcset=""> -->
        </div>
        <!--
          ruoyi后台不支持获取是否开启账户.
          故此处不做隐藏处理. 在ruoyi原前端中是在注册页面定义一个属性手动修改处理.
          <router-link class="register" :to="{ name: 'register' }">注册账户</router-link>
        -->

      </div>
    </a-form-model>
  </div>
</template>

<script>

import QRCode from 'qrcode'
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
// import { getCodeImg } from '@/api/login'
import { LOGIN_USERNAME, LOGIN_PASSWORD, LOGIN_REMEMBERME } from '@/store/mutation-types'
import storage from 'store'
import defaultSettings from '@/config/defaultSettings'
import router from '@/router'
export default {
  components: {
  },
  data() {
    return {
      isChecken: true, // 默认同意
      defaultSettings,
      codeUrl: '',
      isLoginError: false,
      loginErrorInfo: '',
      form: {
        username: 'admin',
        password: 'admin123',
        code: undefined,
        uuid: '',
        rememberMe: false
      },
      rules: {
        username: [{ required: true, message: '请输入帐户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      logining: false,
      captchaEnabled: false,
      redirect: undefined,
      codeImgUrl: '' // 二维码地址
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created() {
    this.getQRCode()
  },
  methods: {
    ...mapActions(['Login', 'Logout']),
    // 获取二维码
    getQRCode() {
      QRCode.toDataURL('微信登录', { width: 240, margin: 0 })
          .then(url => {
            console.log('获取二维码>>>>', url)
            this.codeImgUrl = url
          })
          .catch(err => {
            console.error(err)
          })
    },
    getCode(captchaEnabled) {
      this.captchaEnabled = captchaEnabled
      // getCodeImg().then(res => {
      //   this.captchaEnabled = res.captchaEnabled === undefined ? true : res.captchaEnabled
      //   if (this.captchaEnabled) {
      //     this.codeUrl = 'data:image/gif;base64,' + res.img
      //     this.form.uuid = res.uuid
      //   }
      // })
    },
    getStorage() {
      const username = storage.get(LOGIN_USERNAME)
      const password = storage.get(LOGIN_PASSWORD)
      const rememberMe = storage.get(LOGIN_REMEMBERME)
      if (username) {
        this.form = {
          username: username,
          password: password,
          rememberMe: rememberMe
        }
      }
    },
    rememberMe(e) {
      this.form.rememberMe = e.target.checked
    },
    handleSubmit() {
      if (!this.isChecken) {
        this.$notification.error({ message: '请阅读并同意《用户注册协议》与《隐私协议》' })
        return
      }
      this.logining = true
      console.log('>>>>>', this.$refs.form)

      this.$refs.form.validate(valid => {
        console.log('>>>>1>>>', valid)

        if (valid) {
        console.log('>>>>2>>>', this.form.rememberMe)

          if (this.form.rememberMe) {
            storage.set(LOGIN_USERNAME, this.form.username)
            storage.set(LOGIN_PASSWORD, this.form.password)
            storage.set(LOGIN_REMEMBERME, this.form.rememberMe)
          } else {
            storage.remove(LOGIN_USERNAME)
            storage.remove(LOGIN_PASSWORD)
            storage.remove(LOGIN_REMEMBERME)
          }
        console.log('>>>>3>>>', this.$store)

          this.$store.dispatch('Login', this.form).then((res) => {
            console.log('999', res)
            console.log('$store.state>>>>>>', this.$store.state)

            this.$store.dispatch('GetInfo')
          .then(res => {
            // const roles = res.result && res.result.role
            const roles = res.roles
            // generate dynamic router
            this.$store.dispatch('GenerateRoutes', { roles }).then((accessRoutes) => {
              console.log('accessRoutes>>>>', accessRoutes)
              router.addRoutes(this.$store.getters.routers)
              if (accessRoutes.data.length > 0) {
                if (accessRoutes.data[0]?.children.length > 0) {
                  this.$store.commit('SET_DEFAULTROUTEPATH', '/' + accessRoutes.data[0].path + '/' + accessRoutes.data[0]?.children[0].path)
                  this.$router.push({ path: '/' + accessRoutes.data[0].path + '/' + accessRoutes.data[0]?.children[0].path }).catch(() => { })
                }
              }

              // router.addRoutes(accessRoutes)
              // 请求带有 redirect 重定向时，登录自动重定向到该地址
            })
          })
            // this.$router.push({ path: '/jiashicang/index' }).catch(() => { })
            // this.$router.push({ path: this.redirect || '/' }).catch(() => { })
          }).catch((err) => {
            console.log('err', err)
            this.loading = false
            setTimeout(() => {
              this.logining = false
            }, 1000)
            if (this.captchaEnabled) {
              this.getCode()
            }
          })
          // this.Login(this.form)
          //   .then((res) => this.loginSuccess(res))
          //   .catch(err => this.requestFailed(err))
          //   .finally(() => {
          //     this.logining = false
          //   })
        } else {
          setTimeout(() => {
            this.logining = false
          }, 600)
        }
      })
    },
    loginSuccess(res) {
      this.$router.push({ path: '/' })
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        this.$notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`
        })
      }, 1000)
      this.handleCloseLoginError()
    },
    requestFailed(err) {
      this.isLoginError = true
      this.loginErrorInfo = err
      this.form.code = undefined
      if (this.captchaEnabled) {
        this.getCode()
      }
    },
    handleCloseLoginError() {
      this.isLoginError = false
      this.loginErrorInfo = ''
    },
    // 打开忘记密码
    openOther(path) {
      if (path === 'forgetPassword' && this.captchaEnabled) {
        return
      }
      this.$router.push({ path: '/user/' + path }).catch(() => { })
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  margin: 0 !important;

  /deep/.ant-input {
    height: 4.8vh;
    padding: 0 1.3vh;
    border: 1px solid #E4E4E5;
    font-size: 16px;
    font-family: '思源-Regular';
    border-radius: 10px;
    &:focus {
      border-color:#E4E4E5;
      box-shadow:none;
    }
  }
}

.wjmmBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.6vh;

  span {
    color: #999999;
    cursor: pointer;
  }
  img {
    cursor: pointer;
  }
}
// .main{
//   width: 1920px;
//   height: 1080px;
//   overflow: hidden;
//   background:#d9e2ef url('./img/br.png') no-repeat 100% 46px;

//   &.loginpage-6 {
//     background: url('./img/bg-1.png') no-repeat 100% 100%;
//   }
// }
.user-layout-login {
  text-align: center;

  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  button.login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 4.8vh;
    color: #2878FF;

    &.login-buttonCode {
      padding: 0;
    }

    &.login-buttonSub {
      color: #FFFFFF;
      background: #2878FF;
      border-radius: 26px 26px 26px 26px;
    }
  }

  .user-login-other {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // text-align: left;
    margin-top: 4.1vh;
    padding-bottom: 4.1vh;
    // line-height: 22px;

    // .register {
    //   float: right;
    // }

    .user-login-other-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 2.2vh;

      .b {
        width: 9.8vw;
        height: 0px;
        opacity: 1;
        border: 1px solid #EEEFF1;
      }

      span {
        color: #999999;
      }

    }

    .other-type {

      .other-type-icon {
        width: 3.7vh;
        height:3.7vh;
        margin-right: 1.875vw;
        cursor: pointer;

        &:last-child {
          margin: 0;
        }
      }

    }
  }

  :deep .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 40px;
    background: #F7F7F7;
  }

  // :deep .ant-input-affix-wrapper {
  //   background: #F7F7F7;
  // }
}

.code-box {
  width: 258px;
  padding: 9px 6px;
  margin: 0 auto;
  margin-top: 0.4vh;
  border-radius: 10px;
  background: #F7F7F7;
}

.code-msg {
  width: 100%;
  margin-top: 2.5vh;
  text-align: center;

  .code-msg-icon {
    width: 1.85vh;
    height: 1.85vh;
    margin-right: 4px;
  }

}

</style>
